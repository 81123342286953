var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"row--small top-bar__tabs mt-0 px-0 mx-0"},[_c('v-col',{staticClass:"py-0 px-0 d-flex",attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"prev-icon":"$chevronleft","next-icon":"$chevronright","center-active":""}},[_c('v-tab',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"p-1 font-weight-600 top-bar__item",attrs:{"to":("/instances/" + (_vm.instance.id) + "/general")}},[_vm._v(_vm._s(_vm.$t('heading.instance.tab.siteDetails')))]),(_vm.$store.state.home.app.report_provider_enabled && (_vm.instance.getUserPrivileges('general.view_reports')))?_c('v-tab',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"p-1 font-weight-600 top-bar__item",attrs:{"to":("/instances/" + (_vm.instance.id) + "/monitoring")}},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.tab.monitoring'))+" "),(_vm.hasReportProviderWarning)?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"warning--text warning lighten-5 p-3 px-2 py-1 ml-2",class:_vm.instance.health_checks.report_provider.status == 'error' ? 'cursor-pointer' : '',attrs:{"label":""},nativeOn:{"click":function($event){return _vm.openJavaScriptCodeModal.apply(null, arguments)}}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"warning--text",attrs:{"size":"16"}},[_vm._v("$alertwarning")])],1)]}}],null,false,1680644796)},[_vm._v(" "+_vm._s(_vm.getReportProviderWarningMessage || _vm.$t('tooltip.instance.tab.monitoring.warning'))+" ")]):_vm._e()],1):_vm._e(),(_vm.instance.getUserPrivileges('wordpress.manage_backups'))?_c('v-tab',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"p-1 font-weight-600 top-bar__item",attrs:{"to":("/instances/" + (_vm.instance.id) + "/backups")}},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.tab.backups'))+" "),(_vm.instance.hasAutomaticBackup() && _vm.instance.hasRecentBackup())?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"success--text success lighten-5 p-3 px-2 py-1 ml-2",attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"success--text",attrs:{"size":"16"}},[_vm._v("$alertok")])],1)]}}],null,false,838267097)},[(_vm.instance.backup_details.length)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.backup.recent'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.backup.scheduled'))+" ")])]):_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"error--text error lighten-5 p-3 px-2 py-1 ml-2",attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"error--text",attrs:{"size":"16"}},[_vm._v("$alertwarning")])],1)]}}],null,false,4001965324)},[(!_vm.instance.hasRecentBackup())?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.backup.empty'))+" ")]):_vm._e(),(!_vm.instance.hasAutomaticBackup())?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.backup.disabled'))+" ")]):_vm._e()])],1):_vm._e(),(_vm.instance.getUserPrivileges('wordpress.manage_plugins'))?_c('v-tab',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"p-1 font-weight-600 top-bar__item",attrs:{"to":("/instances/" + (_vm.instance.id) + "/plugins")}},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.tab.plugins'))+" "),(_vm.instance.info.plugins.errors)?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"error--text error lighten-5 p-3 px-2 py-1 ml-2",attrs:{"label":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.instance.info.plugins.errors)+" "),_c('v-icon',{staticClass:"error--text ml-1",attrs:{"size":"16"}},[_vm._v(" $alertwarning ")])],1)]}}],null,false,365494272)},[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.plugins.update', {count: _vm.instance.info.plugins.errors}))+" ")]):_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"success--text success lighten-5 p-3 px-2 py-1 ml-2",attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"success--text",attrs:{"size":"16"}},[_vm._v("$alertok")])],1)]}}],null,false,838267097)},[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.plugins.noUpdate'))+" ")])],1):_vm._e(),_c('v-tab',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"p-1 font-weight-600 top-bar__item",attrs:{"to":("/instances/" + (_vm.instance.id) + "/advanced")}},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.tab.advanced'))+" "),(
            (_vm.instance.debugMode || _vm.instance.maintenanceMode) &&
            !_vm.instance.staging
          )?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"warning--text warning lighten-5 p-3 px-2 py-1 ml-2",attrs:{"label":""}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"warning--text",attrs:{"size":"16"}},[_vm._v("$alertwarning")])],1)]}}],null,false,2180897758)},[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.tab.advanced.mode', {mode: _vm.debugOrMaintenanceModeText}))+" ")]):_vm._e()],1)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":("/hosting/summary?id=" + (this.instance.service_id))}},[_c('span',{staticClass:"p-1 info--text d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16","color":"info"}},[_vm._v("$externallink")]),_vm._v(" "+_vm._s(_vm.$t('general.hosting'))+" ")],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }