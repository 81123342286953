var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 top-bar"},[_c('top-bar-subinstance-bar',_vm._g({key:_vm.instance.id,attrs:{"fetching":_vm.fetching,"instance":_vm.instance}},_vm.$listeners)),(!_vm.fetching)?[_c('div',{staticClass:"px-6"},[(_vm.instance.info)?_c('v-container',{staticClass:"top-bar__container px-0",class:{
          'mt-8': !_vm.$vuetify.breakpoint.lgAndDown,
          'mt-6': _vm.$vuetify.breakpoint.lg,
          'mt-4': _vm.$vuetify.breakpoint.mdAndDown,
        }},[_c('v-row',{staticClass:"justify-space-between mx-0 mb-6",class:_vm.$vuetify.breakpoint.mdAndDown ? 'flex-column' : ''},[_c('top-bar-title',_vm._g({key:_vm.instance.id,attrs:{"fetching":_vm.fetching,"instance":_vm.instance}},_vm.$listeners)),_c('div',{staticClass:"top-bar-title__box d-flex justify-start justify-lg-end align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-wrap' : ''},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex mt-4"},[(_vm.instance.sharedAccess.length)?_c('share-instance-modal',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"align-self-center mr-6"},'div',attrs,false),on),[_c('shares-label-big',_vm._g(_vm._b({attrs:{"instance":_vm.instance}},'shares-label-big',attrs,false),on))],1)]}}],null,false,3979250842)}):_vm._e(),_c('router-link',{staticClass:"d-flex align-center mr-3",attrs:{"to":("/hosting/summary?id=" + (this.instance.hostingId))}},[_c('span',{staticClass:"p-1 info--text d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16","color":"info"}},[_vm._v("$externallink")]),_vm._v(" "+_vm._s(_vm.$t("general.hosting"))+" ")],1)])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-start justify-lg-end align-center",class:_vm.$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''},[(_vm.fetching)?_vm._l((_vm.$vuetify.breakpoint.lgAndDown ? 2 : 5),function(index){return _c('base-icon-button-skeleton',{key:index})}):[_c('share-instance-modal',{attrs:{"instance":_vm.instance},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [(_vm.instance.sharedAccess.length)?_c('div',_vm._g(_vm._b({staticClass:"align-self-center mr-6",class:_vm.$vuetify.breakpoint.smAndDown ? 'd-none' : ''},'div',attrs,false),on),[_c('shares-label-big',{class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-3' : '',attrs:{"instance":_vm.instance,"icon":"$shareIcon","color":"info","text":_vm.$t('button.share'),"small":_vm.$vuetify.breakpoint.smAndDown}})],1):_vm._e(),_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{staticClass:"top-bar__btn",attrs:{"text":_vm.$t('button.share'),"icon":"$shareIcon","color":"info","small":_vm.$vuetify.breakpoint.smAndDown ? true : false}})],1)]}}],null,false,290747255)}),(
                    _vm.instance.getUserPrivileges('wordpress.create_sso_link')
                  )?_c('base-icon-button',{staticClass:"top-bar__btn",class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-3' : '',attrs:{"disabled":!_vm.instance.isActive,"fetching":_vm.fetching,"text":_vm.$t('button.wpAdmin'),"icon":"$wordpress","color":"info","loading":_vm.checkingWpLogin,"small":_vm.$vuetify.breakpoint.smAndDown ? true : false},nativeOn:{"click":function($event){return _vm.openWPLoginModal.apply(null, arguments)}}}):_vm._e(),(
                    this.instance.subinstanceLabel !== 'staging' &&
                    _vm.instance.getUserPrivileges('general.create_staging')
                  )?[(!_vm.state || _vm.state == 'no_staging')?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","nudge-right":"4px","disabled":_vm.instance.subInstances.length < 2,"z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(
                            _vm.instance.getUserPrivileges(
                              'general.create_staging'
                            )
                          )?_c('base-icon-button',{staticClass:"top-bar__btn",class:_vm.$vuetify.breakpoint.smAndDown ? 'ml-3' : '',attrs:{"icon":"$copy","disabled":_vm.instance.subInstances.length >= 2 ||
                            !_vm.instance.isActive,"text":_vm.$t('button.staging'),"small":_vm.$vuetify.breakpoint.smAndDown ? true : false,"fetching":_vm.fetching},nativeOn:{"click":function($event){return _vm.$emit('action-button-staging', _vm.instance)}}}):_vm._e()],1)]}}],null,false,311774863)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tooltip.topBar.staging.create.inProgress"))+" ")])]):(_vm.state && _vm.state != 'no_staging')?_c('v-menu',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","open-on-hover":"","close-on-content-click":false,"nudge-left":"110px","min-width":"272px","nudge-bottom":"64px","origin":"top center"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.state === 'in_progress')?[_c('base-icon-button',{attrs:{"loading":true,"disabled":true,"text":_vm.$t('button.clone'),"fetching":_vm.fetching,"small":_vm.$vuetify.breakpoint.lgAndDown ? true : false}})]:[_c('base-icon-button',{attrs:{"icon":'$xcircle',"color":"error","text":_vm.$t('message.status.staging.failed'),"small":_vm.$vuetify.breakpoint.smAndDown ? true : false,"fetching":_vm.fetching}})]],2)]}}],null,false,1524217537)},[_c('div',{staticClass:"px-4 py-4"},[(_vm.state === 'in_progress')?[_c('p',{staticClass:"p-4 text-center mb-2"},[_vm._v(" "+_vm._s(_vm.$t("tooltip.topBar.staging.create.clone"))+" ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","color":"error","x-small":"","max-height":"24px"}},[_c('span',{staticClass:"p-5 font-weight-600"},[_vm._v(" "+_vm._s(_vm.$t("button.cancelClone"))+" ")])])],1)]:(_vm.state === 'error')?[_c('p',{staticClass:"p-4 text-center mb-2"},[_c('i18next',{attrs:{"translation":_vm.$t('tooltip.topBar.staging.create.error.plain')},scopedSlots:_vm._u([{key:"highlighted",fn:function(){return [_c('strong',[_vm._v(_vm._s(_vm.$t( "tooltip.topBar.staging.create.error.highlighted" )))])]},proxy:true}],null,false,2653219993)})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mr-2 px-2",attrs:{"elevation":"0","color":"primary","x-small":"","max-height":"24px"},on:{"click":function($event){return _vm.$emit('action-button-retry-staging', _vm.instance)}}},[_c('span',{staticClass:"p-5 font-weight-600"},[_vm._v(_vm._s(_vm.$t("button.try")))])]),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","color":"error","max-height":"24px","x-small":""},on:{"click":function($event){return _vm.$emit('action-button-cancel-staging', _vm.instance)}}},[_c('span',{staticClass:"p-5 font-weight-600"},[_vm._v(_vm._s(_vm.$t("button.cancel")))])])],1)]:_vm._e()],2)]):_vm._e()]:(
                    this.instance.subinstanceLabel === 'staging' &&
                    _vm.instance.getUserPrivileges('general.push_to_live')
                  )?_c('base-icon-button',{staticClass:"primary-btn top-bar__btn",attrs:{"disabled":!_vm.instance.isActive,"loading":_vm.loadingPushToLive || _vm.instance.hasActionInProgress(),"text":_vm.$t('button.pushToLive'),"icon":"$send","fetching":_vm.fetching},nativeOn:{"click":function($event){return _vm.openPushToLiveModal.apply(null, arguments)}}}):_vm._e(),(
                    !_vm.$vuetify.breakpoint.lgAndDown &&
                    _vm.instance.plan_settings.copy_instance_allowed &&
                    _vm.instance.getUserPrivileges('general.copy_instance')
                  )?_c('base-icon-button',{staticClass:"top-bar__btn",class:_vm.$vuetify.breakpoint.lgAndDown ? 'ml-3' : '',attrs:{"disabled":!_vm.instance.isActive,"fetching":_vm.fetching,"icon":"$copy","text":_vm.$t('button.copy'),"small":_vm.$vuetify.breakpoint.lgAndDown ? true : false},nativeOn:{"click":function($event){return _vm.$emit('action-button-copy', _vm.instance)}}}):_vm._e(),[(
                      _vm.instance.status.wordpress_available >
                      _vm.instance.status.wordpress
                    )?[(_vm.instance.getUserPrivileges('wordpress.update'))?_c('base-icon-button',{staticClass:"top-bar__btn",attrs:{"disabled":!_vm.instance.isActive,"fetching":_vm.fetching,"icon":"$update","loading":_vm.checkingUpdates || _vm.instance.hasUpdateInProgress(),"text":_vm.$t('button.update'),"small":_vm.$vuetify.breakpoint.lgAndDown},nativeOn:{"click":function($event){return _vm.openUpdateModal.apply(null, arguments)}}}):_vm._e()]:[(_vm.instance.getUserPrivileges('wordpress.update'))?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","nudge-bottom":"0px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{staticClass:"top-bar__btn",attrs:{"fetching":_vm.fetching,"icon":"$update","disabled":true,"text":_vm.$t('button.update'),"small":_vm.$vuetify.breakpoint.lgAndDown},nativeOn:{"click":function($event){return _vm.openUpdateModal.apply(null, arguments)}}})],1)]}}],null,false,2531237054)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.topBar.update")))])]):_vm._e()]],(_vm.instance.getUserPrivileges('wordpress.clear_cache'))?_c('base-icon-button',{class:_vm.$vuetify.breakpoint.lgAndDown ? 'ml-3' : '',attrs:{"disabled":!_vm.instance.isActive,"fetching":_vm.fetching,"icon":"$restore","text":_vm.$t('button.cache'),"small":_vm.$vuetify.breakpoint.lgAndDown ? true : false},nativeOn:{"click":function($event){return _vm.$emit('action-button-clearcache', _vm.instance)}}}):_vm._e(),(_vm.instance.getUserPrivileges('general.delete_instance'))?_c('base-icon-button',{staticClass:"top-bar__btn",attrs:{"fetching":_vm.fetching,"icon":"$xcircle","text":_vm.$t('button.delete'),"color":'error'},nativeOn:{"click":function($event){return _vm.$emit('action-button-delete', _vm.instance)}}}):(false)?_c('instance-item-menu',_vm._g({attrs:{"inInstance":true,"id":this.instance.id,"instance":this.instance},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('base-icon-button',{class:_vm.$vuetify.breakpoint.lgAndDown ? 'ml-3' : '',attrs:{"on":on,"fetching":_vm.fetching,"attrs":attrs,"icon":"$morehorizontal","text":!_vm.$vuetify.breakpoint.lgAndDown
                          ? _vm.$t('button.more')
                          : '',"small":_vm.$vuetify.breakpoint.lgAndDown}})]}}],null,false,3018226017)},_vm.$listeners)):_vm._e()]],2)])],1),(_vm.instance.isActive)?_c('instance-top-bar-tabs',_vm._g({attrs:{"instance":_vm.instance,"fetching":_vm.fetching},on:{"openModal":function($event){_vm.openedModal = true}}},_vm.$listeners)):_vm._e()],1):_vm._e()],1)]:[_c('top-bar-skeleton')],_c('v-dialog',{attrs:{"transition":"custom-dialog-transition","width":"560px"},model:{value:(_vm.openedModal),callback:function ($$v) {_vm.openedModal=$$v},expression:"openedModal"}},[_c('report-provider-tracking-code-modal',{attrs:{"instance":_vm.instance},on:{"modal-close":function($event){_vm.openedModal = false}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }